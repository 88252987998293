import { AnalyticsPeriod } from "../common";
import { IAchievementState } from "../types/achievement.types";
import { IChild } from "../types/profile.types";
import api from "./api.service";

export interface IProfileAnalytic {
  avatarId: number;
  name: string;
  from: string;
  to: string;
  timeOnEducation: number;
  rightAnswersPercentage: number;
  tasksFinished: number;
  prevTimeOnEducation: number;
  prevRightAnswersPercentage: number;
  prevTasksFinished: number;
  lastActivityTime: string;
  rightAnswerPercentageBreakdown: {
    percentage: number;
    topic: string;
  }[];
}

export const getChildren = async (profileId: number) => {
  try {
    const { data } = await api.get<IChild[]>(
      `/v1/profiles/${profileId}/students`
    );

    return data;
  } catch (error) {
    return [];
  }
};

export const getChildAchievements = async (
  profileId: number,
  childId: number
) => {
  const { data } = await api.get<IAchievementState>(
    `/v1/profiles/${profileId}/students/${childId}/achievement`
  );

  return data;
};

export const createChildren = async (
  profileId: number,
  child: Omit<IChild, "studentId" | "subscription">
) => {
  const { data } = await api.post<IChild>(
    `/v1/profiles/${profileId}/students`,
    child
  );

  return data;
};

export const buyCollectionItem = async (
  profileId: number,
  childId: number,
  itemId: number
) => {
  const { data } = await api.put<IAchievementState>(
    `/v1/profiles/${profileId}/students/${childId}/buy?item-id=${itemId}`
  );

  return data;
};

export const getStudentAnalytics = async (
  profileId: number,
  studentId: number,
  period: AnalyticsPeriod
) => {
  const { data } = await api.get<IProfileAnalytic>(
    `/v1/profiles/${profileId}/students/${studentId}/analytics?period=${period}`
  );

  return data;
};
