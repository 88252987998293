import { combineReducers } from "redux";

import { RootState } from "../types";
import AchievementsReducer from "./achievements.reducer";
import ChildTopicsReducer from "./child-topic.reducer";
import CursorReducer from "./cursor.reducer";
import FinishedReducer from "./finished.reducer";
import GameReducer from "./game.reducer";
import LessonReducer from "./lesson.reducer";
import ProfileReducer from "./profile.reducer";
import RootReducer from "./root.reducer";
import TeacherReducer from "./teacher.reducer";

const rootReducer = combineReducers<RootState>({
  profile: ProfileReducer,
  achievement: AchievementsReducer,
  childTopics: ChildTopicsReducer,
  lesson: LessonReducer,
  teacher: TeacherReducer,
  finished: FinishedReducer,
  root: RootReducer,
  cursor: CursorReducer,
  game: GameReducer,
});

export default rootReducer;
