import { createReducer } from "@reduxjs/toolkit";

import {
  setAchievement,
  setDefaultAchievement,
  setProgram,
} from "../actions/achievement-actions";
import { Games } from "../common";
import { DifficultyLevel, ProgramType } from "../types";
import { IAchievementState } from "../types/achievement.types";

const initialState: IAchievementState = {
  achievementId: 0,
  coinBalance: 0,
  currentTopicId: 0,
  currentTopicNumber: 0,
  canStudyToday: true,
  canWorkOnMistakes: true,
  currentTaskNumber: 0,
  currentLessonNumber: 0,
  currentLevel: 0,
  experienceTillNextLevel: 0,
  difficultyLevel: DifficultyLevel.LOW,
  program: ProgramType.JUNIOR,
  collectionItems: {},
  canBuyCollectionItem: false,
  gamesLevel: {
    [Games.MakeTheOrder]: { "1": 1 },
    [Games.BeforeOrAfter]: { "1": 1 },
    [Games.SpatialOrientation]: { "1": 1 },
    [Games.Symmetry]: { "1": 1 },
    [Games.SoundQuest]: { "1": 1 },
  },
};

const AchievementsReducer = createReducer(initialState, (builder) => {
  builder.addCase(setAchievement, (state, { payload }) => ({
    ...payload,
    gamesLevel: {
      ...initialState.gamesLevel,
      ...payload.gamesLevel,
    },
  }));
  builder.addCase(setProgram, (state, { payload }) => ({
    ...state,
    program: payload,
  }));
  builder.addCase(setDefaultAchievement, () => ({
    ...initialState,
  }));
});

export default AchievementsReducer;
