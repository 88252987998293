import { createReducer } from "@reduxjs/toolkit";

import { setChildTopics } from "../actions/child-topics-actions";
import { IChildTopicsState } from "../types/child-topics.types";

const initialState: IChildTopicsState = {
  topics: [],
};

const ChildTopicsReducer = createReducer(initialState, (builder) => {
  builder.addCase(setChildTopics, (state, { payload }) => ({
    ...state,
    topics: payload,
  }));
});

export default ChildTopicsReducer;
