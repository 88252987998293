import { createAction } from "@reduxjs/toolkit";

import { IChild, IProfileState } from "../types/profile.types";
import {
  PROFILE_DROP_CHILD,
  PROFILE_SET_CHILD,
  PROFILE_SET_CHILDREN,
  PROFILE_SET_DATA,
} from "./types/profile-types";

export const setProfile =
  createAction<Omit<IProfileState, "selectedChild">>(PROFILE_SET_DATA);

export const setProfileChild = createAction<IChild>(PROFILE_SET_CHILD);
export const dropProfileChild = createAction(PROFILE_DROP_CHILD);
export const setProfileChildren = createAction<IChild[]>(PROFILE_SET_CHILDREN);
