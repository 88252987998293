import { createReducer } from "@reduxjs/toolkit";

import {
  selectItem,
  setGameLevel,
  setGameType,
  setShapes,
  setWrongAnswer,
} from "../actions/game-actions";
import { Games } from "../common";
import { IGameState } from "../types/game.types";

const initialState: IGameState = {
  activeGameType: Games.MakeTheOrder,
  difficultyLevel: 1,
  selectedItem: undefined,
  shapes: undefined,
  wrongAnswer: undefined,
};

const GameReducer = createReducer(initialState, (builder) => {
  builder.addCase(selectItem, (state, { payload }) => ({
    ...state,
    selectedItem: payload,
  }));
  builder.addCase(setShapes, (state, { payload }) => ({
    ...state,
    shapes: payload,
  }));
  builder.addCase(setWrongAnswer, (state, { payload }) => ({
    ...state,
    wrongAnswer: payload,
  }));
  builder.addCase(setGameType, (state, { payload }) => ({
    ...state,
    activeGameType: payload,
  }));
  builder.addCase(setGameLevel, (state, { payload }) => ({
    ...state,
    difficultyLevel: payload,
  }));
});

export default GameReducer;
