import { createReducer } from "@reduxjs/toolkit";

import {
  dropProfileChild,
  setProfile,
  setProfileChild,
  setProfileChildren,
} from "../actions/profile-actions";
import { IProfileState } from "../types/profile.types";

const initialState: IProfileState = {
  profileId: 0,
  email: "",
  selectedChild: sessionStorage.getItem("currentChild")
    ? JSON.parse(sessionStorage.getItem("currentChild")!)
    : null,
  children: [],
};

const ProfileReducer = createReducer(initialState, (builder) => {
  builder.addCase(setProfile, (state, { payload }) => ({
    ...state,
    ...payload,
  }));
  builder.addCase(setProfileChild, (state, { payload }) => ({
    ...state,
    selectedChild: payload,
  }));
  builder.addCase(dropProfileChild, (state) => ({
    ...state,
    selectedChild: null,
  }));
  builder.addCase(setProfileChildren, (state, { payload }) => ({
    ...state,
    children: payload,
  }));
});

export default ProfileReducer;
