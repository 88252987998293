import { createReducer } from "@reduxjs/toolkit";

import { setFinished } from "../actions/finished-actions";
import { IFinishedState } from "../types/finished.types";

const initialState: IFinishedState = {
  lessonNumber: undefined,
  topicNumber: undefined,
};

const FinishedReducer = createReducer(initialState, (builder) => {
  builder.addCase(setFinished, (state, { payload }) => ({
    ...state,
    ...payload,
  }));
});

export default FinishedReducer;
