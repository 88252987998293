import { createReducer } from "@reduxjs/toolkit";

import { clearServerError, setServerError } from "../actions/root-actions";
import { IRootState } from "../types/root.types";

const initialState: IRootState = {
  serverError: false,
};

const RootReducer = createReducer(initialState, (builder) => {
  builder.addCase(setServerError, () => ({
    serverError: true,
  }));
  builder.addCase(clearServerError, () => ({
    serverError: false,
  }));
});

export default RootReducer;
