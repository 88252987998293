import axios from "axios";

import { setServerError } from "../actions/root-actions";
import config from "../config";
import store from "../store";
import { keycloak } from "./keycloak.service";

const api = axios.create({
  baseURL: config.baseUrl,
});

api.interceptors.request.use(
  async (req) => {
    if (keycloak.isTokenExpired(5)) {
      await keycloak.updateToken(5);
    }

    if (keycloak.token) {
      req.headers.Authorization = `Bearer ${keycloak.token}`;
    }

    return req;
  },
  (error) => {
    console.error(error);

    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  async (req) => req,
  (error) => {
    console.error(error);

    if (/^5\d{2}$/.test(error.response.status)) {
      store.dispatch(setServerError());
    }

    return Promise.reject(error);
  }
);

export default api;
