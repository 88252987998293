import { createAction } from "@reduxjs/toolkit";

import { ProgramType } from "../types";
import { IAchievementState } from "../types/achievement.types";
import {
  ACHIEVEMENT_SET_DATA,
  ACHIEVEMENT_SET_DEFAULT_DATA,
  ACHIEVEMENT_SET_PROGRAM,
} from "./types/achievement-types";

export const setAchievement =
  createAction<IAchievementState>(ACHIEVEMENT_SET_DATA);

export const setDefaultAchievement = createAction(ACHIEVEMENT_SET_DEFAULT_DATA);

export const setProgram = createAction<ProgramType>(ACHIEVEMENT_SET_PROGRAM);
