import { createAction } from "@reduxjs/toolkit";

import {
  SET_CURSOR_CUSTOM,
  SET_CURSOR_CUSTOM_CORRECT,
  SET_CURSOR_CUSTOM_INCORRECT,
  SET_CURSOR_DEFAULT,
} from "./types/cursor-types";

export const setCursorDefault = createAction(SET_CURSOR_DEFAULT);
export const setCursorCustom = createAction(SET_CURSOR_CUSTOM);
export const setCursorCustomCorrect = createAction(SET_CURSOR_CUSTOM_CORRECT);
export const setCursorCustomIncorrect = createAction(
  SET_CURSOR_CUSTOM_INCORRECT
);
