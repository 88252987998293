import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "/node_modules/primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "./index.css";

import { PrimeReactProvider } from "primereact/api";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import SmartLook from "smartlook-client";

import { Roles } from "./common";
import { initFirebase } from "./common/firebase";
import config from "./config";
import init, { hasRole } from "./services/auth.service";
import { keycloak } from "./services/keycloak.service";
import store from "./store";

const App = React.lazy(() => import("./App"));
const AppAdmin = React.lazy(() => import("./AppAdmin"));
const AppTeacher = React.lazy(() => import("./AppTeacher"));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const render = () => {
  let Application = App;

  if (hasRole(Roles.Admin) || hasRole(Roles.ContentManager)) {
    Application = AppAdmin;
  }

  if (hasRole(Roles.Teacher)) {
    Application = AppTeacher;
  }

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <PrimeReactProvider>
            <Application />
          </PrimeReactProvider>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  );
};

if (config.isProd) {
  SmartLook.init("6333431c18bee47871ff40737688f8b956580792", { region: "eu" });
  initFirebase();
}

if (window.location.pathname.toLowerCase() === "/register") {
  keycloak.init({});

  const query = window.location.search
    ? `&${window.location.search.replace("?", "")}`
    : "";
  const url = keycloak.createRegisterUrl({
    redirectUri: `${window.location.origin}${window.location.search}`,
  });

  window.location.href = `${url}${query}`;
} else {
  init().then(() => render());
}
