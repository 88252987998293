import { TaskType } from "../types";

export const topicTypes = [
  {
    name: "Thematic",
    value: TaskType.THEMATIC,
  },
  {
    name: "General development",
    value: TaskType.DEVELOPMENTAL,
  },
];

export const topicTypesCZ = [
  {
    name: "Tematický",
    value: TaskType.THEMATIC,
  },
  {
    name: "Obecný rozvoj",
    value: TaskType.DEVELOPMENTAL,
  },
];

export enum Roles {
  Admin = "ADMIN",
  ContentManager = "CONTENT-MANAGER",
  User = "USER",
  Teacher = "TEACHER",
}

export const PathViaRole = {
  [Roles.Admin]: "/admin/",
  [Roles.ContentManager]: "/admin/database/",
};

export enum CardItemStatus {
  Completed = "completed",
  InProgress = "in-progress",
  Locked = "locked",
}

export enum Coins {
  Big = "big",
  Medium = "medium",
  Small = "small",
}

export enum SubscriptionPeriod {
  Month = "MONTH",
  HalfYear = "HALF_YEAR",
  Year = "YEAR",
}

export enum Subscriptions {
  Basic = "basic",
  Standard = "standard",
  Premium = "premium",
  None = "none",
}

export enum SubscriptionPlanTypes {
  Demo = "DEMO",
  Standard = "STANDARD",
  Premium = "PREMIUM",
  PremiumPlus = "PREMIUM_PLUS",
}

export enum AnalyticsPeriod {
  OneDay = "ONE_DAY",
  SevenDays = "SEVEN_DAYS",
  ThirtyDays = "THIRTY_DAYS",
}

export enum GameShapes {
  Circle = "circle",
  Rectangle = "rectangle",
  Triangle = "triangle",
  Square = "square",
}

export enum Games {
  MakeTheOrder = "SHAPE_SORTING",
  BeforeOrAfter = "CHRONOLOGICAL_ORDER",
  SpatialOrientation = "SPATIAL_ORIENTATION",
  Symmetry = "SYMMETRY",
  SoundQuest = "SOUND_QUEST",
}
