import { createAction } from "@reduxjs/toolkit";

import { Games } from "../common";
import {
  SET_GAME_LEVEL,
  SET_GAME_TYPE,
  SET_SELECTED_ITEM,
  SET_SHAPES,
  SET_WRONG_ANSWER,
} from "./types/game-types";

export const selectItem = createAction<
  { name: string; shape: string; index: number } | undefined
>(SET_SELECTED_ITEM);
export const setShapes = createAction<
  { name: string; count: number }[] | undefined
>(SET_SHAPES);
export const setWrongAnswer = createAction<string | undefined>(
  SET_WRONG_ANSWER
);
export const setGameType = createAction<Games>(SET_GAME_TYPE);
export const setGameLevel = createAction<number>(SET_GAME_LEVEL);
