import { createReducer } from "@reduxjs/toolkit";

import { setTeacher } from "../actions/teacher-actions";
import { ITeacherState } from "../types/teacher.types";

const initialState: ITeacherState = {
  teacherId: 0,
  name: "",
  surname: "",
  position: "",
  phone: "",
  kindergartenId: 0,
};

const TeacherReducer = createReducer(initialState, (builder) => {
  builder.addCase(setTeacher, (state, { payload }) => ({
    ...payload,
  }));
});

export default TeacherReducer;
