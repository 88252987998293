import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCVfJIyUKXKS94S3D56h9fB-q7-dvdALSc",
  authDomain: "lucky-altar-387610.firebaseapp.com",
  projectId: "lucky-altar-387610",
  storageBucket: "lucky-altar-387610.appspot.com",
  messagingSenderId: "1057303816343",
  appId: "1:1057303816343:web:ab53f616ad621fb28761d2",
  measurementId: "G-91YZ10TV1B",
};

export const initFirebase = () => {
  const app = initializeApp(firebaseConfig);

  getAnalytics(app);
};
