import api from "./api.service";
import { keycloak } from "./keycloak.service";

export interface IProfile {
  profileId: number;
  email: string;
}

export const changePassword = async (email: string, password: string) => {
  const { data } = await api.put("/v1/profiles/reset-password", {
    email,
    password,
  });

  return data;
};

export const getProfile = async () => {
  const { data } = await api.get<IProfile>(
    `/v1/profiles?email=${keycloak.tokenParsed?.email}`
  );

  return data;
};

export const createProfile = async () => {
  const { data } = await api.post<IProfile>(`/v1/profiles`, {
    email: keycloak.tokenParsed?.email,
  });

  return data;
};
