import { createReducer } from "@reduxjs/toolkit";

import {
  setCursorCustom,
  setCursorCustomCorrect,
  setCursorCustomIncorrect,
  setCursorDefault,
} from "../actions/cursor-actions";

const initialState = "";

const CursorReducer = createReducer(initialState, (builder) => {
  builder.addCase(setCursorCustom, () => "custom-cursor");
  builder.addCase(setCursorCustomCorrect, () => "custom-cursor-correct");
  builder.addCase(setCursorCustomIncorrect, () => "custom-cursor-incorrect");
  builder.addCase(setCursorDefault, () => "");
});

export default CursorReducer;
