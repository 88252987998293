import SmartLook from "smartlook-client";

import { setAchievement } from "../actions/achievement-actions";
import { setProfile } from "../actions/profile-actions";
import { Roles } from "../common";
import store from "../store";
import { getChildAchievements, getChildren } from "./child.service";
import { keycloak } from "./keycloak.service";
import { createProfile, getProfile, IProfile } from "./profile.service";

export const hasRole = (role: Roles) => keycloak.hasRealmRole(role);

export const init = async () => {
  try {
    const auth = await keycloak.init({
      onLoad: "login-required",
      checkLoginIframe: false,
    });

    if (!auth) {
      throw new Error("Not authorized!");
    }

    let profile: IProfile;

    try {
      profile = await getProfile();
    } catch (error) {
      profile = await createProfile();
    }

    if (
      hasRole(Roles.Admin) ||
      hasRole(Roles.ContentManager) ||
      hasRole(Roles.Teacher)
    ) {
      store.dispatch(setProfile({ ...profile!, children: [] }));
    } else {
      SmartLook.identify(profile!.profileId, {
        email: profile!.email,
      });

      const children = await getChildren(profile!.profileId);

      store.dispatch(
        setProfile({
          ...profile!,
          children,
        })
      );

      const selectedChild = store.getState().profile.selectedChild;

      if (selectedChild) {
        const achievement = await getChildAchievements(
          profile!.profileId,
          selectedChild!.studentId
        );

        store.dispatch(setAchievement(achievement));
      }
    }
  } catch (e) {
    console.warn(e);
  }
};

export const isAuthenticated = () => !!keycloak.authenticated;

export const logout = () => {
  sessionStorage.removeItem("currentChild");
  keycloak.logout();
};

export const getUsername = () => keycloak.tokenParsed?.email;

export default init;
