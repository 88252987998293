import { IAchievementState } from "./achievement.types";
import { IChildTopicsState } from "./child-topics.types";
import { IFinishedState } from "./finished.types";
import { IGameState } from "./game.types";
import { ILessonState } from "./lesson.types";
import { IProfileState } from "./profile.types";
import { IRootState } from "./root.types";
import { ITeacherState } from "./teacher.types";

export interface IPagination {
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalElements: number;
}

export enum TaskTypes {
  GRAPH_QUESTION_TEXT_ANSWER_TASK = "GRAPH_QUESTION_TEXT_ANSWER_TASK",
  GRAPH_QUESTION_GRAPH_ANSWER_SLIDER_TASK = "GRAPH_QUESTION_GRAPH_ANSWER_SLIDER_TASK",
  GRAPH_QUESTION_GRAPH_ANSWER_TASK = "GRAPH_QUESTION_GRAPH_ANSWER_TASK",
  TEXT_QUESTION_GRAPH_ANSWER_TASK = "TEXT_QUESTION_GRAPH_ANSWER_TASK",
}

export enum DifficultyLevel {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HARD = "HARD",
}

export enum ProgramType {
  JUNIOR = "JUNIOR",
  JUNIOR_PLUS = "JUNIOR_PLUS",
  FIRST_GRADE = "FIRST_GRADE",
}

export enum TaskStatus {
  INACTIVE = "INACTIVE",
  PUBLISHED = "PUBLISHED",
}

export enum TaskType {
  THEMATIC = "THEMATIC",
  DEVELOPMENTAL = "DEVELOPMENTAL",
}

export interface ITopic {
  topicId: number;
  orderNumber: number;
  code: string;
  description: string;
  descriptionCZ: string;
  imageUrl: string;
  videoUrl: string;
  posterImageUrl: string;
}

export interface ITag {
  tagId: number;
  tagName: string;
  topicId?: number;
}

export interface ITask {
  difficultyLevel: DifficultyLevel;
  type: TaskType;
  age: number;
  tagId: number;
  tagName?: string;
  taskId: number;
  rightAnswer: number;
  topicId: number;
  questionId: number;
  question: string;
  questionAudioUrl: string;
  status: TaskStatus;
}

export interface ITask1 extends ITask {
  template: TaskTypes.GRAPH_QUESTION_TEXT_ANSWER_TASK;
  questionImgUrl: string;
  answer1: string;
  answer2: string;
  answer3: string;
}

export interface ITask2 extends ITask {
  template: TaskTypes.TEXT_QUESTION_GRAPH_ANSWER_TASK;
  answer1ImgUrl: string;
  answer2ImgUrl: string;
  answer3ImgUrl?: string;
}

export interface ITask3 extends ITask {
  template: TaskTypes.GRAPH_QUESTION_GRAPH_ANSWER_TASK;
  questionImgUrl: string;
  answer1ImgUrl: string;
  answer2ImgUrl: string;
  answer3ImgUrl: string;
}

export interface ITask4 extends ITask {
  template: TaskTypes.GRAPH_QUESTION_GRAPH_ANSWER_SLIDER_TASK;
  slide2ImgUrl: string;
  slide3Answer1ImgUrl: string;
  slide3Answer2ImgUrl: string;
  slide3Answer3ImgUrl: string;
  slide3Question: string;
  slide3QuestionId: number;
  slide3QuestionAudioUrl: string;
  timer: number;
}

export type ITaskType = ITask1 | ITask2 | ITask3 | ITask4;

export interface IViewCardProps<T extends ITask> {
  card: T;
  onSuccessClick: (answer: number) => void;
  onFailedClick: (answer: number) => void;
  isAutoPlayed?: boolean;
  firstLessonTooltip?: boolean;
}

export type RootState = {
  profile: IProfileState;
  achievement: IAchievementState;
  childTopics: IChildTopicsState;
  teacher: ITeacherState;
  lesson: ILessonState;
  finished: IFinishedState;
  root: IRootState;
  cursor?: string;
  game: IGameState;
};
