import { createAction } from "@reduxjs/toolkit";

import { ITaskType } from "../types";
import { ILessonState } from "../types/lesson.types";
import { SET_LESSON, SET_LESSON_CURRENT_TASK } from "./types/lesson-types";

export const setLesson = createAction<ILessonState | undefined>(SET_LESSON);
export const setLessonCurrentTask = createAction<ITaskType | undefined>(
  SET_LESSON_CURRENT_TASK
);
