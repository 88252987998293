import { createReducer } from "@reduxjs/toolkit";

import { setLesson, setLessonCurrentTask } from "../actions/lesson-actions";
import { ILessonState } from "../types/lesson.types";

const initialState: ILessonState = {
  topicId: 0,
  studentId: 0,
  lessonId: 0,
  lessonNumber: 0,
  taskIds: [],
};

const LessonReducer = createReducer(initialState, (builder) => {
  builder.addCase(setLesson, (state, { payload }) =>
    payload
      ? {
          ...state,
          ...payload,
        }
      : initialState
  );
  builder.addCase(setLessonCurrentTask, (state, { payload }) => ({
    ...state,
    currentTask: payload,
  }));
});

export default LessonReducer;
